import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div className='container text-center'>
        <div className='text-center mb-5'>
          <h1 className='mb-5'>Welcome to the Pepes community website!</h1>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 m-3 card border-light h-100" style={{backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%)', backdropFilter: 'blur(6px)', backgroundColor: 'rgba(245, 245, 245, .4)'}}>
              <div className="card-body d-flex flex-column align-items-start">
                  <div className='text-center'>
                      <h4 className="card-title text-dark">Leaderboards</h4>
                      <p className="card-text">See who is winning the race on points!</p>
                      <div><Link to="/leaderboards" className='btn btn-dark mt-auto'>Leaderboards</Link></div>
                  </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 m-3 card border-light h-100" style={{backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%)', backdropFilter: 'blur(6px)', backgroundColor: 'rgba(245, 245, 245, .4)'}}>
                <div className="card-body d-flex flex-column align-items-start">
                  <div className='text-center'>
                    <h4 className="card-title text-dark">Top Holders</h4>
                    <p className="card-text">Who is the biggest holder in The Pepes collection?</p>
                    <div><Link to="/holders" className='btn btn-dark mt-auto'>Top Holders</Link></div>
                  </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 m-3 card border-light h-100" style={{backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 100%)', backdropFilter: 'blur(6px)', backgroundColor: 'rgba(245, 245, 245, .4)'}}>
                <div className="card-body d-flex flex-column align-items-start">
                  <div className='text-center'>
                    <h4 className="card-title text-dark">Community Blog</h4>
                    <p className="card-text">A place for holders to make blogs and share news.</p>
                    <a href="#" className="btn btn-dark mt-auto disabled">Coming soon</a>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div>
          <div className='h4'>
            <a href='https://www.thepepes.com' className='text-dark text-decoration-none'>Back to www.ThePepes.com</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;