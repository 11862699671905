import React from 'react';
import AirdropPointsTable from './AirdropPointsTable';

function Leaderboards() {
  return (
    <div className='vh-100 d-flex align-items-center'>
      <div className='container'>
        <div className='text-center'>
          <h1 className='mb-3'>Points Leaderboard</h1>
          <AirdropPointsTable/>
          <h1>How To Play</h1>
          <p>Getting points is easy. Just don't sell!</p>
          <p>Every day, you gain 2 points for every ordinal that you're holding. You also get points for buying more Pepes!</p>
          <p>Be careful though! Selling a Pepe will cost you quite a few points!</p>
        </div>
      </div>
    </div>
  );
}

export default Leaderboards;
