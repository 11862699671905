import React, { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

function TopTenList() {
    const [topHolders, setTopHolders] = useState([]);
    const [totalHolders, setTotalHolders] = useState(0); // Initialize totalHolders state

    useEffect(() => {
        const fetchTopHolders = async () => {
            try {
                console.log(apiUrl);
                const response = await fetch(`${apiUrl}/topHolders`, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });
                if (response.ok) {
                    const data = await response.json(); 
                    setTopHolders(data.topHoldersList); // Set topHoldersList state
                    setTotalHolders(data.totalHolders); // Set totalHolders state
                } else {
                    console.error('Failed to fetch top holders:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch top holders:', error);
            }
        };

        fetchTopHolders();
    }, []);

    return (
        <>
            <div className='text-center'>
                <div className='mb-3'>
                    <h1 className='mb-4'>Top Holders</h1>
                    <h5>Total Unique Holders: {totalHolders}</h5>
                </div>
                <div className='border' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <table className="table">
                        <thead className="table-dark sticky-top">
                            <tr>
                                <th scope="col">Rank</th>
                                <th scope="col">Wallet</th>
                                <th scope="col">Pepes Owned</th>
                                <th scope="col">% of Total</th>
                            </tr>
                        </thead>
                        <tbody className='table-light'>
                            {topHolders.map((holder, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                    <a href={`https://magiceden.io/ordinals/wallet?walletAddress=${holder.walletId}`} className="text-truncate d-inline-block w-50" style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }} target="_blank" rel="noopener noreferrer">
                                            {holder.walletId}
                                        </a>
                                    </td>
                                    <td>{holder.ordAmount}</td>
                                    <td>{((holder.ordAmount) / 10000 * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default TopTenList;
