import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <>
            <div style={{backgroundColor: '#1c1c1c'}}>
                <div className="container d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
                    <div className="col col-lg-2 d-flex justify-content-between">
                        <a href="https://community.thepepes.com/" className="d-inline-flex link-body-emphasis text-decoration-none">
                            <img src='/logo.png' height="78" alt="Logo" />
                        </a>
                    </div>
                    <div className="col-lg-10 offcanvas-md offcanvas-end" tabIndex="-1" id="mainMenu">
                        <div className="offcanvas-header">
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#mainMenu" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body p-0 d-flex flex-wrap align-items-center flex-column flex-md-row justify-content-evenly justify-content-md-end">
                            <ul className="nav d-md-flex align-items-center flex-column flex-md-row mb-2 justify-content-center mb-md-0">
                                <li><Link to="/" className='nav-link m-2 text-light text-bold'><strong>Home</strong></Link></li>
                                <li><Link to="/leaderboards" className='nav-link m-2 text-light'><strong>Leaderboards</strong></Link></li>
                                <li><Link to="/holders" className='nav-link m-2 text-light'><strong>Top Holders</strong></Link></li>
                            </ul>
                            <div className="d-flex justify-content-end soc-icons">
                                <a href="https://www.coingecko.com/en/nft/the-pepes" rel="nofollow" target="_blank" className="twitterx"><svg width="20" height="20" fill="#fff"><use href="#icon-twitterx"></use></svg></a>
                                <a href="https://discord.com/invite/xv8dVtYtxa" rel="nofollow" target="_blank" className="discord"><svg width="27" height="27" fill="#5865f2"><use href="#icon-discord"></use></svg></a>
                                <a href="https://www.coingecko.com/en/nft/the-pepes" rel="nofollow" target="_blank"><img src="img/1.png" width="50" alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;
