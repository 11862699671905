import React, { useState, useEffect } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

function AirdropPointsTable() {
    const [holderPointsInfo, setHolderPointsInfo] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        fetchHolderPoints();
    }, []);

    const fetchHolderPoints = async () => {
        try {
            const response = await fetch(`${apiUrl}/getHolderPoints`);
            if (response.ok) {
                const data = await response.json();
                setHolderPointsInfo(data);
            } else {
                console.error('Failed to fetch holders:', response.statusText);
            }
        } catch (error) {
            console.error('Failed to fetch holders:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleSearchSubmit = async () => {
        try {
            const response = await fetch(`${apiUrl}/getSearchHolderPoints?walletId=${searchValue}`);
            if (response.ok) {
                const data = await response.json();
                setSearchResult(data);
            } else {
                console.error('Failed to search for wallet:', response.statusText);
                setSearchResult(null);
            }
        } catch (error) {
            console.error('Failed to search for wallet:', error);
            setSearchResult(null);
        }
    };

    const handleResetResults = () => {
        setSearchResult(null);
        setSearchValue('');
    };

    return (
        <>
            <div className='m-3 d-flex justify-content-center'>
                <div className='w-50'>
                    <input className='form-control' type="text" placeholder='Wallet search' value={searchValue} onChange={handleSearchChange} />
                </div>
                <button className='btn btn-success' onClick={handleSearchSubmit}>Search</button>
                <button className='btn btn-dark' onClick={handleResetResults}>Reset Results</button>
            </div>
            <div className='border' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <table className="table">
                    <thead className="table-dark sticky-top">
                        <tr>
                            <th scope="col">Rank</th>
                            <th scope="col">Wallet</th>
                            <th scope="col">Points</th>
                            <th scope="col">Point change</th>
                        </tr>
                    </thead>
                    <tbody className='table-light'>
                        {searchResult ? (
                            <tr>
                                <th scope="row">1</th>
                                <td>
                                    <a href={`https://magiceden.io/ordinals/wallet?walletAddress=${searchResult.walletId}`} className="text-truncate d-inline-block w-50" style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }} target="_blank" rel="noopener noreferrer">
                                        {searchResult.walletId}
                                    </a>
                                </td>
                                <td>{searchResult.totalPoints}</td>
                                <td>{searchResult.pointsDifference}</td>
                            </tr>
                        ) : (
                            holderPointsInfo.map((holder, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                        <a href={`https://magiceden.io/ordinals/wallet?walletAddress=${holder.walletId}`} className="text-truncate d-inline-block w-50" style={{ direction: 'rtl', overflow: 'hidden', textOverflow: 'ellipsis' }} target="_blank" rel="noopener noreferrer">
                                            {holder.walletId}
                                        </a>
                                    </td>
                                    <td>{holder.totalPoints}</td>
                                    <td>{holder.pointsDifference}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AirdropPointsTable;
