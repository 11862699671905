import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "../src/components/Home.js";
import Navbar from "../src/components/Navbar.js";
import Holders from "../src/components/Holders.js";
import Leaderboards from "../src/components/Leaderboards.js";

function App() {
  return (
    <Router>
      <div className='vh-100'>
      <div style={{backgroundImage: 'linear-gradient(to left, rgba(4, 171, 32, 0.8) 50%, rgba(4, 171, 32, 0.4) 50%, rgba(4, 171, 32, 0.8) 100%), linear-gradient(to right, rgba(4, 171, 32, 0.8) 50%, rgba(4, 171, 32, 0.4) 50%, rgba(4, 171, 32, 0.8) 100%)', minHeight: '100vh'}}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/holders" element = {<Holders />} />
            <Route path="/leaderboards" element = {<Leaderboards />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;